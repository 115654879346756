import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-function',
  templateUrl: './function.component.html',
  styleUrls: ['./function.component.scss']
})
export class FunctionComponent implements OnInit {

  @Input() functionIcon: string;
  @Input() functionName: string;
  constructor() { }

  ngOnInit() {
  }

}
