import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit {

  isFunctionsPage: boolean;
  isQrScanPage: boolean;
  isGuestPage: boolean;
  guestUrl: string;

  constructor() { 
  }

  ngOnInit() {
    this.isFunctionsPage = true;
    this.isQrScanPage = false;
    this.isGuestPage = false;
  }

  homeButton() {
    this.isFunctionsPage = true;
    this.isQrScanPage = false;
    this.isGuestPage = false;
  }

  processFunction(functionName: string) {
    this.isFunctionsPage = false;
    this.isQrScanPage = true;
    this.isGuestPage = false;
  }
  
  intersessionDone(done: boolean) {
    this.isFunctionsPage = true;
    this.isQrScanPage = false;
    this.isGuestPage = false;
  }

  onScanStatus(guestUrl: string) {
    this.isFunctionsPage = false;
    this.isQrScanPage = false;
    this.isGuestPage = true;
    console.log(guestUrl);
    this.guestUrl = guestUrl;
  }

  photoShootDone(done: boolean) {
    this.isFunctionsPage = false;
    this.isQrScanPage = true;
    this.isGuestPage = false;    
  }

}
