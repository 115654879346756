import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@angular/flex-layout';
import { MatButtonModule, MatCheckboxModule, MatInputModule, MatIconModule, MatSelectModule, MatDatepickerModule, MatNativeDateModule, MatToolbarModule, MatDialogModule, MatProgressSpinnerModule } from '@angular/material';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    FlexLayoutModule,
    MatButtonModule, 
    MatCheckboxModule, 
    MatInputModule, 
    MatIconModule,
    MatSelectModule, 
    MatDatepickerModule, 
    MatNativeDateModule,
    MatToolbarModule,
    MatDialogModule,
    MatProgressSpinnerModule
  ],
  exports: [
    FlexLayoutModule,
    MatButtonModule, 
    MatCheckboxModule, 
    MatInputModule, 
    MatIconModule,
    MatSelectModule, 
    MatDatepickerModule, 
    MatNativeDateModule,
    MatToolbarModule,
    MatDialogModule,
    MatProgressSpinnerModule
  ]
})
export class MaterialModule { }
