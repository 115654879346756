import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-functions',
  templateUrl: './functions.component.html',
  styleUrls: ['./functions.component.scss']
})
export class FunctionsComponent implements OnInit {

  @Output() functionName: EventEmitter<any> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  scanGuestClick() {
    this.functionName.emit("scanGuest");
  }  

}
