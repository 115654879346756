import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { MaterialModule } from './shared/material/material.module';

import { LoaderService } from './services/loader.service';

import { LoaderComponent } from './components/loader/loader.component';
import { AlertDialogComponent } from './components/alert-dialog/alert-dialog.component';
import { MainComponent } from './pages/main/main.component';
import { FunctionsComponent } from './pages/functions/functions.component';
import { FunctionComponent } from './components/function/function.component';
import { QrscanComponent } from './pages/qrscan/qrscan.component';
import { GuestComponent } from './pages/guest/guest.component';

@NgModule({
  declarations: [
    AppComponent,
    LoaderComponent,
    AlertDialogComponent,
    MainComponent,
    FunctionsComponent,
    FunctionComponent,    
    QrscanComponent,
    GuestComponent,
  ],
  entryComponents: [
    AlertDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ZXingScannerModule,
    MaterialModule
  ],
  providers: [
    LoaderService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
