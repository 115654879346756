import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { LoaderService } from '../../services/loader.service';
import { environment } from '../../../environments/environment';

import { AlertDialogComponent } from '../../components/alert-dialog/alert-dialog.component';

@Component({
  selector: 'app-guest',
  templateUrl: './guest.component.html',
  styleUrls: ['./guest.component.scss']
})
export class GuestComponent implements OnInit {

  @Input() guestUrl: string;
  @Output() photoShootDone: EventEmitter<any> = new EventEmitter();

  guestId: string;
  validForm: boolean;
  apiGuestsReadUrl: string = environment.apiUrlPrefix + '/guests_read';
  apiGuestsUpdateUrl: string = environment.apiUrlPrefix + '/guests_shoot_submit';

  guestForm: FormGroup;
  formSubmitted: boolean = false;
  validationMessages = {
    'imageNumberFrom': [
      { type: 'required', message: 'Image Number From is required' }
    ],
    'imageNumberTo': [
      { type: 'required', message: 'Image Number To is required' }
    ],
  };   

  constructor(
    private formBuilder: FormBuilder,
    private httpClient: HttpClient,
    private loaderService: LoaderService,
    public dialog: MatDialog
  ) { }

  ngOnInit() {
    this.validForm = false;
    this.createForms();
    this.readGuest();
  }

  readGuest() {
    this.loaderService.show();
    const formData = new FormData();
    formData.append('guestUrl', this.guestUrl);    
    this.httpClient.post(this.apiGuestsReadUrl, formData)
    .subscribe(
      (res) => {
        // console.log(res);
        this.loaderService.hide();
        this.guestId = res['DT_RowId'];
        this.guestForm.get('guestNumber').setValue(res['mg_number']);
        this.guestForm.get('guestName').setValue(res['mg_name']);
        this.validForm = true;
        // this.guestForm.get('guestTime').setValue(res['mg_time']);
      },
      (err) => {
        console.log(err);
        this.loaderService.hide();
        let errorMessage = 'Server Errror. Please try again later.';
        if(typeof err.status !== 'undefined') {
          errorMessage = errorMessage + ' Status Code: ' + err.status;
        }
        this.openDialog(errorMessage);
      }
    );    
  }

  createForms() {
    this.guestForm = this.formBuilder.group({
      guestNumber: [
        {
          value: '',
          disabled: true
        }
      ],
      guestName: [
        {
          value: '',
          disabled: true
        }
      ],
      // guestTime: [
      //   {
      //     value: '',
      //     disabled: true
      //   }
      // ],                    
      // imageNumberFrom: ['', Validators.required ],
      // imageNumberTo: ['', Validators.required ],
    });    
  } 

  openDialog(title: string): void {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '250px',
      data: { title: title }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.photoShootDone.emit(null);
    });
  }

  onSubmitGuestForm() {
    this.loaderService.show();
    const formData = new FormData();
    formData.append('guestId', this.guestId);
    // formData.append('imageFrom', this.guestForm.get('imageNumberFrom').value);
    // formData.append('imageTo', this.guestForm.get('imageNumberTo').value);
    // formData.append('shootAt', new Date().getTime().toString());
    this.httpClient.post(this.apiGuestsUpdateUrl, formData)
    .subscribe(
      (res) => {
        // console.log(res);
        this.loaderService.hide();
        if(res['success'] === true) {
          this.photoShootDone.emit(null);
        } else if(res['success'] === false){
          let errorMessage = '';
          errorMessage = 'Form Submission Error. Please try again later.';
          if(typeof res['errorResult'] !== 'undefined') {
            errorMessage = errorMessage + ' Code: ' + res['errorResult']['code'];
          }
          this.openDialog(errorMessage);
        } else {
          this.openDialog('Form Submission Error. Please try again later. Code: FFFF');
        }
      },
      (err) => {
        // console.log(err);
        this.loaderService.hide();
        let errorMessage = 'Server Errror. Please try again later.';
        if(typeof err.status !== 'undefined') {
          errorMessage = errorMessage + ' Status Code: ' + err.status;
        }
        this.openDialog(errorMessage);
      }
    );    
  }    

}