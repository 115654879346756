import { Component, OnInit, OnDestroy, Output, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { MatDialog } from '@angular/material/dialog';
import { LoaderService } from '../../services/loader.service';
import { environment } from '../../../environments/environment';

import { AlertDialogComponent } from '../../components/alert-dialog/alert-dialog.component';

@Component({
  selector: 'app-qrscan',
  templateUrl: './qrscan.component.html',
  styleUrls: ['./qrscan.component.scss']
})
export class QrscanComponent implements OnInit, OnDestroy {

  apiIntersessionUpdateUrl: string = environment.apiUrlPrefix + '/guests_intersession_submit';

  scannerEnabled: boolean;
  @Output() scanStatus: EventEmitter<any> = new EventEmitter();
  @Output() intersession: EventEmitter<any> = new EventEmitter();

  constructor(
    private httpClient: HttpClient,
    private loaderService: LoaderService,
    public dialog: MatDialog    
  ) { }

  openDialog(title: string): void {
    const dialogRef = this.dialog.open(AlertDialogComponent, {
      width: '250px',
      data: { title: title }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
      this.intersession.emit(null);
    });
  }  

  ngOnInit() {
    this.scannerEnabled = true;
  }
  
  ngOnDestroy() {
    console.log('On Destroy');
  }

  camerasFoundHandler($event) {
    console.log("camerasFoundHandler() " + JSON.stringify($event));
  }

  camerasNotFoundHandler($event) {
    console.log("camerasNotFoundHandler() " + JSON.stringify($event));
  }  

  scanSuccessHandler($event) {
    console.log("scanSuccessHandler() " + JSON.stringify($event));
    this.scanStatus.emit($event);
    // this.scannerEnabled = false;
  } 

  scanErrorHandler($event) {
    console.log("scanErrorHandler() " + JSON.stringify($event));
    // this.scannerEnabled = false;
  } 
  
  scanFailureHandler($event) {
    console.log("scanFailureHandler() " + JSON.stringify($event));
    // this.scannerEnabled = false;
  } 
  
  scanCompleteHandler($event) {
    console.log("scanCompleteHandler() " + JSON.stringify($event));
    // this.scannerEnabled = false;
  }   

  intersessionClick() {
    this.loaderService.show();
    const formData = new FormData();
    formData.append('guestId', '0');
    this.httpClient.post(this.apiIntersessionUpdateUrl, formData)
    .subscribe(
      (res) => {
        // console.log(res);
        this.loaderService.hide();
        if(res['success'] === true) {
          this.intersession.emit(null);
        } else if(res['success'] === false){
          let errorMessage = '';
          errorMessage = 'Intersession Submission Error. Please try again later.';
          if(typeof res['errorResult'] !== 'undefined') {
            errorMessage = errorMessage + ' Code: ' + res['errorResult']['code'];
          }
          this.openDialog(errorMessage);
        } else {
          this.openDialog('Intersession Submission Error. Please try again later. Code: FFFF');
        }
      },
      (err) => {
        // console.log(err);
        this.loaderService.hide();
        let errorMessage = 'Server Error. Please try again later.';
        if(typeof err.status !== 'undefined') {
          errorMessage = errorMessage + ' Status Code: ' + err.status;
        }
        this.openDialog(errorMessage);
      }
    );    
  }     

}
